import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
import { unregister /*registerServiceWorker*/ } from "./registerServiceWorker";
unregister();

ReactDOM.render(<Root />, document.getElementById("root"));
//registerServiceWorker();
