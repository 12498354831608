import React from "react";
import { Link } from "react-router-dom";

class DiscountedProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productID: this.props.productID,
    };
  }

  render() {
    let image = process.env.REACT_APP_IMG_SRC + this.props.data.URL_img;
    console.log(image);
    /*if (this.state.URL_img === null || this.state.URL_img.length <= 0) {
      image = "/../img/logo.png";
    }*/
    let unit = this.props.data.canBuyPieces ? "kr/st" : "kr/kg";

    return (
      <div className="col-md-2">
        <Link to={"product/" + this.props.data.productID}>
          <div class="card">
            <h5 className="card-header">{this.props.data.name}</h5>
            <div className="d-flex align-items-center min-height">
              <img
                className="card-img-bottom"
                src={image}
                alt={this.props.data.name}
              />
            </div>
            <h5 className="card-title d-flex justify-content-end mb-0">
              <span className="badge badge-primary">
                Pris: {this.props.data.priceForOne} {unit}
              </span>
            </h5>
          </div>
        </Link>
      </div>
    );
  }
}

export default DiscountedProduct;
