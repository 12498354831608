import React from "react";
import axios from "./bin/axios_gsh";
import { Link } from "react-router-dom";
import Modal from "./components/Modal.js";
import Table from "./components/Table.js";

import UpdateAmountForm from "./components/UpdateAmountForm";
import ProductMessage from "./components/ProductMessage";

import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { format } from "date-fns";
import sv from "date-fns/locale/sv";
import "react-datepicker/dist/react-datepicker.css";
import history from "./components/BrowserHistory";

registerLocale("sv", sv);
class Cart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      header: [
        "ID",
        "Artnr",
        "Artikel",
        "Pris (SEK)",
        "Totalt (SEK)",
        "Mängd",
        "",
        "",
      ],
      skipDataColumns: [0, 1],
      columnSizing: [
        "1px",
        "1px",
        null,
        "170px",
        "155px",
        "215px",
        "1px",
        "1px",
      ],
      data: [],

      vikt: 1,
      antal: 2,
      kolli: 3,

      lock: true,

      DelatPåslagAnvänds: false,
      hasUpdated: false,

      earliestDeliveryDate: null,
      earliestDeliveryTimes: [],
      deliveryDates: [],

      validDate: false,
      excludedDates: [],
      disabledDates: [],
      maximumDate: null,

      responseID: null,

      error: null,
      showErrorModal: false,

      templateName: "",
      messageCart: [...this.props.cart],
      infoMessage: null,
    };
    this.props.updateCart();

    this.checkTime = this.checkTime.bind(this);
    this.saveTemplate = this.saveTemplate.bind(this);
    this.updateTemplateName = this.updateTemplateName.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
    this.updateData = this.updateData.bind(this);
    this.updateMessage = this.updateMessage.bind(this);
    this.updateMessages = this.updateMessages.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.updateProduct = this.updateProduct.bind(this);

    this.setModal = this.setModal.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.sendOrder = this.sendOrder.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getDeliveryDateOptions = this.getDeliveryDateOptions.bind(this);
    this.updateExcludedDates = this.updateExcludedDates.bind(this);
    this.getEarliestDeliveryDay = this.getEarliestDeliveryDay.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(() => this.checkTime(), 5000); //every 5 seconds
  }

  checkTime() {
    let current = new Date();
    for (let i = 0; i < this.state.deliveryDates.length; i++) {
      let date = this.state.deliveryDates[i];

      if (
        new Date(current.toLocaleString()) >= new Date(date.toLocaleString()) &&
        !this.state.hasUpdated
      ) {
        this.setState(
          {
            hasUpdated: true,
          },
          () => {
            this.getDeliveryDateOptions();
          }
        );

        break;
      }
    }
  }

  componentDidUpdate(prevProps) {
    let previousCart = JSON.stringify(prevProps.cart);
    let newCart = JSON.stringify(this.props.cart);

    if (previousCart !== newCart || this.state.data.length === 0) {
      let messageCart = [];
      for (let i = 0; i < this.props.cart.length; i++) {
        const product = this.props.cart[i];
        let newProduct = { ...product };

        for (let j = 0; j < this.state.messageCart.length; j++) {
          const message = this.state.messageCart[j];
          if (
            product.productID == message.productID &&
            product.unitID == message.unitID
          ) {
            newProduct.message = message.message;
            break;
          }
        }

        messageCart.push(newProduct);
      }

      this.setState(
        {
          messageCart: messageCart,
        },
        () => {
          this.getDeliveryDateOptions();
          this.updateData();
        }
      );
    }
  }

  removeProduct(e, product) {
    e.preventDefault();
    let self = this;

    axios.post("cart/remove/", product).then(function (response) {
      self.props.updateCart();
    });
  }

  updateData() {
    // Run when cart is updated

    let copyCart = [...this.props.cart];
    let messageCart = [...this.state.messageCart];

    for (let i = 0; i < copyCart.length; i++) {
      copyCart[i].message = messageCart[i].message;
    }

    let newData = [];
    // priceKolli = priceOne * unitsize
    // origin:         (productInfo.origin)? productInfo.origin : (productInfo.landskod)? productInfo.landskod : '',
    let DelatPåslagAnvänds = false;
    for (let i = 0; i < copyCart.length; i++) {
      const product = copyCart[i];

      if (product.DelatPåslagAnvänds) {
        DelatPåslagAnvänds = true;
      }
      //let fav = <FavButton isFav={product.fav} makeFav={(e) => { e.preventDefault(); self.updateFav(product.productID, product.fav);} }/>;
      let formattedProduct = {
        productID: product.productID,
        Nummer: product.Nummer,
        item: null,
        price: null,
        priceForAll: null,
        amountform: null,
        message: null,
        delete: null,
      };

      let ursprung = product.landskod ? product.landskod : "";

      let per =
        (product.GrundPris ? product.GrundPris : 0.0)
          .toFixed(2)
          .replace(".", ",") +
        (product.Pris_per_styck
          ? " per st"
          : product.DelatPåslagAnvänds
          ? " per kg *"
          : " per kg");
      per =
        product.unitID === this.state.kolli
          ? (product.GrundPris && product.Förpackningsstorlek
              ? product.GrundPris * product.Förpackningsstorlek
              : 0.0
            )
              .toFixed(2)
              .replace(".", ",") + " per kolli"
          : per;
      formattedProduct.item = (
        <div>
          <Link
            className="text-break font-weight-bold text-dark"
            to={"/product/" + product.productID}
          >
            {product.Benämning}
          </Link>
          <br />
          {ursprung && ursprung !== "ÖV" ? (
            <span
              alt={ursprung}
              className={
                "icon-flag flag-icon flag-icon-" + (ursprung + "").toLowerCase()
              }
              rel="tooltip"
              data-toggle="tooltip"
              data-placement="bottom"
              title={product.ursprungsland}
            />
          ) : (
            <span
              alt={ursprung}
              className={"icon-flag flag-icon flag-icon-öv"}
              rel="tooltip"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Övriga länder"
            >
              öv
            </span>
          )}
          {product.krav ? (
            <img
              alt="KRAV-märkt"
              className="icon-flag"
              src={"/icon/krav.jpg"}
              rel="tooltip"
              data-toggle="tooltip"
              data-placement="bottom"
              title="KRAV-märkt"
            />
          ) : (
            ""
          )}
          {product.eko ? (
            <img
              alt="Ekologisk"
              className="icon-flag"
              src={"/icon/eko.png"}
              rel="tooltip"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Ekologisk"
            />
          ) : (
            ""
          )}
          {" | "}
          <Link
            className="text-break text-muted"
            to={"/product/" + product.productID}
          >
            {product.Nummer}
          </Link>
        </div>
      );
      formattedProduct.price = (
        <div>
          {per}
          <br />
          <span className="small">+{product.moms} moms</span>
        </div>
      );

      formattedProduct.priceForAll = (
        <div>
          {(product.pris ? product.pris : 0.0).toFixed(2).replace(".", ",")}
          <br />
          <span className="small">
            inkl. moms:{" "}
            {(product.PrisInkluisveMoms ? product.PrisInkluisveMoms : 0.0)
              .toFixed(2)
              .replace(".", ",")}
          </span>
        </div>
      );

      let meta = {
        productID: product.productID,
        Pris_per_styck: product.Pris_per_styck,
        Ej_Delbar: product.Ej_Delbar,
        Förpackningsstorlek: product.Förpackningsstorlek,
        Stegom: product.Stegom,
        unitID: product.unitID,
        amount: product.amount,
      };
      formattedProduct.amountform = (
        <UpdateAmountForm
          meta={meta}
          updateProduct={this.updateProduct}
        ></UpdateAmountForm>
      );
      formattedProduct.message = (
        <div>
          <div
            className="dropdown"
            data-toggle="tooltip"
            rel="tooltip"
            data-placement="bottom"
            title="Lägg till ett meddelande"
          >
            <button
              style={{
                backgroundImage: !product.message
                  ? "url('/../icon/add_comment-24px.svg')"
                  : "url('/../icon/mode_comment-24px.svg')",
                backgroundSize: "46px 46px",
              }}
              className="btn btn-svg icon btn-link rounded-0 dropdown-toggle"
              type="button"
              id="meddelande"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            ></button>
            <div className="dropdown-menu p-0" aria-labelledby="meddelande">
              <ProductMessage
                message={product.message}
                updateMessage={(message) =>
                  this.updateMessage(product.productID, product.unitID, message)
                }
              />
            </div>
          </div>
        </div>
      );

      formattedProduct.delete = (
        <button
          type="button"
          className="btn btn-danger icon-custom small float-right"
          onClick={(e) => {
            this.removeProduct(e, {
              productID: product.productID,
              unit: product.unitID,
            });
          }}
        >
          <img src="/../icon/trash2.0.png" alt="Ta bort" />
        </button>
      );

      // asdasdasdasd newProduct.amount = product.amount + " " + product.unitName;

      newData.push(formattedProduct);
    }

    let total = (
      <span>
        {(this.props.totalPrice ? this.props.totalPrice : 0.0)
          .toFixed(2)
          .replace(".", ",")}
        <br />
        <span className="small">
          inkl. moms:{" "}
          {(this.props.totalPriceVAT ? this.props.totalPriceVAT : 0.0)
            .toFixed(2)
            .replace(".", ",")}
        </span>
      </span>
    );

    newData.push(["", "", "Totalt:", "", total, "", "", ""]);

    this.setState({
      data: newData,
      DelatPåslagAnvänds: DelatPåslagAnvänds,
    });
  }

  updateProduct(oldProduct, newProduct) {
    let self = this;

    let info = {
      productID: oldProduct.productID,
      oldUnit: oldProduct.unit,
      newUnit: newProduct.unit,
      amount: newProduct.amount,
    };

    axios.post("cart/update", info).then(function (response) {
      self.props.updateCart();
    });
  }

  getErrorMessage() {
    let message = "";
    if (this.state.showWarning) {
      message = (
        <div className="alert alert-warning" role="alert">
          {this.state.warningMessage}
        </div>
      );
    }
    if (this.state.showError) {
      message = (
        <div className="alert alert-danger" role="alert">
          {this.state.errorMessage}
        </div>
      );
    }
    if (this.state.showSuccess) {
      message = (
        <div className="alert alert-success" role="alert">
          {this.state.successMessage}
        </div>
      );
    }
    if (this.state.showSuccessUpdate) {
      message = (
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          {this.state.showSuccessUpdateMsg}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }

    return message;
  }

  updateMessage(productID, unitID, message) {
    let self = this;
    let messageCart = [...this.state.messageCart];

    for (let i = 0; i < messageCart.length; i++) {
      if (
        productID == messageCart[i].productID &&
        unitID == messageCart[i].unitID
      ) {
        messageCart[i].message = message;
        break;
      }
    }

    this.setState(
      {
        messageCart: messageCart,
      },
      () => {
        this.updateData();
      }
    );
  }

  async updateMessages() {
    let self = this;

    let promises = [];
    for (let i = 0; i < this.state.messageCart.length; i++) {
      const product = this.state.messageCart[i];
      // Async problem
      if (product.message !== "") {
        let promise = await axios.post("cart/product/message", product);
        promises.push(promise);
      }
    }

    await Promise.all(promises).then((values) => {});
  }

  closeModal() {
    this.setState({
      responseID: null,
    });
  }

  saveTemplate() {
    let self = this;
    axios
      .post("order/template/add/cart", { name: this.state.templateName })
      .then((response) => {
        response = response.data;
        if (response.success) {
          if (response.error) {
            self.setState({
              infoMessage: (
                <span>
                  Varukorgen sparades till en mall, men några av artiklarna
                  kunde inte läggas till i mallen.{" "}
                  <Link to={"/lists/list/" + response.data.id}>
                    Klicka här.
                  </Link>
                </span>
              ),
            });
          } else {
            self.setState({
              infoMessage: (
                <span>
                  Varukorgen sparades till en mall.{" "}
                  <Link to={"/lists/list/" + response.data.id}>
                    Klicka här.
                  </Link>
                </span>
              ),
            });
          }
        } else {
          self.setState({
            infoMessage: (
              <span>Ett fel har skett och mallen lades inte till.</span>
            ),
          });
        }
      });
  }

  updateTemplateName(e) {
    e.preventDefault();

    this.setState({
      templateName: e.currentTarget.value,
    });
  }

  render() {
    let maximumDate = this.state.maximumDate;
    let excludedDates = [...this.state.excludedDates];
    let earliestDeliveryDate = this.state.earliestDeliveryDate;

    let message = this.getErrorMessage();
    let exception = this.state.DelatPåslagAnvänds ? (
      <div>
        <br />
        <pre style={{ fontFamily: "Arial" }}>
          {" "}
          <i>* Delat pris</i>
        </pre>
      </div>
    ) : null;

    const showCart = this.props.cart && this.props.cart.length > 0;
    //let orderLink = <Link className="btn btn-primary" target="_blank" to={'/order/' + this.state.responseID}>Öppna</Link>
    return (
      <div>
        <div className="row">
          <div className="col pb-3">
            <h1>Varukorgen</h1>
            {!showCart && (
              <div>
                <div className="row">
                  <div className="col-md-2 pb-3">
                    <Link className="btn btn-gsh btn-lg" to="index">
                      Beställ mer
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <p>Det finns för närvarande inga varor i varukorgen</p>
                  </div>
                </div>
              </div>
            )}

            {showCart && (
              <div className="row">
                <div className="col-md-6">
                  <Link className="btn btn-gsh btn-lg" to="index">
                    Beställ mer
                  </Link>
                </div>
                <div className="col-md-6 pb-3">
                  <div className="customer-control">
                    <div className="input-group input-group-lg">
                      <input
                        type="text"
                        onChange={this.updateTemplateName}
                        className="form-control"
                        name="templateName"
                        id="templateName"
                        refs="templateName"
                        placeholder="Namn"
                        value={this.state.templateName}
                      />
                      <div className="input-group-append">
                        <button
                          type="submit primary"
                          className={
                            "btn btn-primary rounded-right" +
                            (this.state.validTemplateName ? "" : " is-invalid")
                          }
                          ref="templateName"
                          onClick={this.saveTemplate}
                        >
                          Spara som Inköpslista
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.showErrorModal && (
              <Modal outsideClickCloses={false}>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <h2>{this.state.error.title}</h2>
                      <p>{this.state.error.description}</p>
                    </div>
                  </div>
                </div>
              </Modal>
            )}
            {this.state.infoMessage && (
              <div className="alert alert-info" role="alert">
                {this.state.infoMessage}
              </div>
            )}
            {this.state.orderMessage && (
              <div className="alert alert-warning" role="alert">
                {this.state.orderMessage.description}
              </div>
            )}
            {message}
            {showCart && (
              <div className="border rounded">
                <Table
                  className={"order-table"}
                  clickFunction={this.getItemNr}
                  columnSizing={this.state.columnSizing}
                  countRows={false}
                  headers={this.state.header}
                  skipDataColumns={this.state.skipDataColumns}
                  data={this.state.data}
                  identifierIndex={0}
                />
              </div>
            )}
            {exception}
          </div>
        </div>
        {showCart && (
          <div className="row">
            <div className="col py-3">
              <form>
                <div className="row offset-md-6">
                  <div className="form-group col-md-12">
                    <label>Meddelande på order</label>
                    <textarea
                      className="form-control"
                      rows="5"
                      ref="comment"
                    ></textarea>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group form-group-lg offset-md-6 col-md-3">
                    <label>Referens</label>
                    <input
                      type="text"
                      className="form-control form-control-lg "
                      ref="reference"
                      placeholder="Referens"
                    />
                  </div>
                  <div className="form-group form-group-lg col-md-3">
                    <label>Önskat leveransdatum</label>
                    <DatePicker
                      className="form-control form-control-lg"
                      dateFormat="yyyy/MM/dd"
                      selected={
                        !this.props.deliveryDate || this.state.lock
                          ? ""
                          : new Date(this.props.deliveryDate)
                      }
                      onChange={this.changeDate}
                      minDate={earliestDeliveryDate}
                      maxDate={maximumDate}
                      locale="sv"
                      excludeDates={excludedDates}
                      dayClassName={(date) => {
                        return this.state.disabledDates.some((d) => {
                          return (
                            date.getDate() === d.getDate() &&
                            date.getMonth() === d.getMonth()
                          );
                        })
                          ? "disabled-delivery"
                          : "";
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group offset-md-9 col-md-3">
                    <button
                      type="submit"
                      onClick={this.sendOrder}
                      className="btn btn-gsh btn-lg col-md-12"
                      disabled={!this.props.deliveryDate || this.state.lock}
                    >
                      {!this.props.deliveryDate || this.state.lock
                        ? "Välj ett leveransdatum"
                        : "Slutför Beställningen"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }

  async sendOrder(e) {
    e.preventDefault();
    let self = this;

    this.setState(
      {
        lock: true,
      },
      async () => {
        let info = {
          force: false,
          reference: this.refs.reference.value,
          comment: this.refs.comment.value,
        };

        try {
          this.updateMessages().then(() => {
            let deliveryDate = new Date(self.props.deliveryDate);
            let earliestDeliveryDate = self.state.earliestDeliveryDate;
            deliveryDate.setHours(0, 0, 0);
            earliestDeliveryDate.setHours(0, 0, 0);
            if (deliveryDate >= earliestDeliveryDate && self.state.lock) {
              axios.post("order/add", info).then(function (response) {
                if (!response.data.error) {
                  axios.get("cart/clear").then(function (success) {
                    success = success.data;
                    self.props.updateCart();
                    self.setState({
                      infoMessage: null,
                    });

                    history.push("../approved/" + response.data.id, {
                      error: success,
                    });
                  });
                } else {
                  self.setState({
                    orderMessage: response.data.error,
                    lock: false,
                  });
                }
              });
            } else {
              self.setState(
                {
                  orderMessage: {
                    title: "",
                    description:
                      "Ordern skickades inte in, då leveransdatumet är utgånget",
                  },
                  lock: false,
                },
                () => {
                  self.props.updateCart();
                  self.getDeliveryDateOptions();
                }
              );
            }
          });
        } catch (error) {
          this.setState(
            {
              lock: false,
            },
            () => {
              self.props.updateCart();
              self.getDeliveryDateOptions();
            }
          );
        }
      }
    );
  }

  setModal(value) {
    this.setState({
      showErrorModal: value,
    });
  }

  getDeliveryDateOptions() {
    let self = this;
    axios.get("user/company/deliveries/").then(function (response) {
      response = response.data;
      let deliveryDates = [];
      response.forEach((element) => {
        let date = new Date(element.Datum);
        deliveryDates.push(date);
      });

      self.setState(
        {
          deliveryDates: deliveryDates,
          earliestDeliveryDate: deliveryDates[0],
          hasUpdated: false,
        },
        () => {
          self.updateExcludedDates();
        }
      );
    });
  }

  changeDate(date) {
    let self = this;
    let deliveryDate = null;
    let validDate = false;
    if (date !== null) {
      deliveryDate = new Date(date);
      if (
        Object.prototype.toString.call(deliveryDate) === "[object Date]" &&
        !isNaN(deliveryDate.getTime())
      ) {
        validDate = true;
      }
    }

    if (validDate) {
      date = format(date, "yyyy-MM-dd") + "T00:00:00.000Z";
      axios.post("cart/date", { date: date }).then(function (response) {
        response = response.data;
        self.setState(
          {
            validDate: !response.error,
            lock: false,
          },
          () => {
            self.props.updateCart();
          }
        );
      });
    }
  }

  updateExcludedDates() {
    let excludedDates = [];
    let current = new Date(new Date().getTime());
    let maxDay =
      this.state.deliveryDates.length > 0
        ? new Date(
            this.state.deliveryDates[this.state.deliveryDates.length - 1]
          )
        : new Date(current.setDate(current.getDate()));

    for (let day = new Date(); day <= maxDay; day.setDate(day.getDate() + 1)) {
      let found = false;

      this.state.deliveryDates.forEach((date) => {
        if (
          date.getDate() === day.getDate() &&
          date.getMonth() === day.getMonth() &&
          date.getUTCFullYear() === day.getUTCFullYear()
        ) {
          found = true;
        }
      });

      if (!found) {
        excludedDates.push(new Date(day));
      }
    }

    this.setState(
      {
        excludedDates: excludedDates,
        maximumDate: maxDay,
      },
      () => {
        this.getEarliestDeliveryDay();
      }
    );
  }

  getEarliestDeliveryDay() {
    let self = this;

    // Get earliest delivery date for all products in user's cart
    axios.get("order/delivery").then(function (response) {
      response = response.data;
      axios.get("user/current").then(function (deliveryDetails) {
        deliveryDetails = deliveryDetails.data;
        deliveryDetails = {
          plockDagar: deliveryDetails.AntalPlockdagar,
          stoppTid: deliveryDetails.StoppTid,
        };

        let dates = [];
        let products = self.props.cart;

        response.forEach((date) => {
          let inCart = false;
          if (date.ID_Produkt !== null) {
            products.forEach((product) => {
              if (date.ID_Produkt + "" === product.productID + "") {
                inCart = true;
                date.Benämning = product.Benämning;
              }
            });

            if (inCart) {
              dates.push(date);
            }
          }
        });
        // Todays date
        //let tempDate = new Date();

        // Test row
        //tempDate.setDate(tempDate.getDate()-1);

        let earliestDeliveryDate = new Date(self.state.earliestDeliveryDate); //new Date(tempDate.getTime());
        earliestDeliveryDate.setHours(0, 0, 0);
        let changed = false;
        let stopProduct = [];
        let first = self.state.deliveryDates[0];

        dates.forEach((date) => {
          if (date.TidigasteLeveransdatum !== null) {
            let stopDate = date.TidigasteLeveransdatum;

            let compare = new Date(stopDate);
            // Test row
            //compare.setDate(compare.getDate()+4);

            if (compare > self.state.deliveryDates[0]) {
              if (compare > earliestDeliveryDate) {
                earliestDeliveryDate = compare;
              }

              let artikelnummer = null;
              self.props.cart.forEach((product) => {
                if (date.ID_Produkt === product.productID) {
                  artikelnummer = product.Nummer;
                }
              });

              if (compare > first) {
                first = new Date(compare);
              }
              //stopProduct.push((date.name + " ("+ ((date === dates[0])? "tidigast leverans " : "") + ((compare.getUTCDate() < 10)? "0" + compare.getUTCDate() : compare.getUTCDate()) + "/" + ((compare.getUTCMonth() + 1 < 10)? "0" + (compare.getUTCMonth()+1) : (compare.getUTCMonth()+1)) + ")"));
              //(date === dates[0])? "tidigast leverans " : ""
              stopProduct.push(artikelnummer + " " + date.Benämning);
              changed = true;
            }

            // Ditt tidigast möjliga leveransdatum är 29/5 på grund av ARTIKELNUMMER ARTIKELNAMN som finns i din varukorg
          }
        });

        let found = false;
        let replace = null;
        self.state.deliveryDates.forEach((date) => {
          if (
            date.getDate() === earliestDeliveryDate.getDate() &&
            date.getMonth() === earliestDeliveryDate.getMonth() &&
            date.getUTCFullYear() === earliestDeliveryDate.getUTCFullYear()
          ) {
            found = true;
          }

          if (!replace && date > earliestDeliveryDate) {
            replace = date;
          }
        });

        let message = null;
        let showWarning = false;

        if (earliestDeliveryDate > self.state.deliveryDates[0]) {
          let day =
            first.getUTCDate() < 10
              ? "0" + first.getUTCDate()
              : first.getUTCDate();
          let month =
            first.getUTCMonth() + 1 < 10
              ? "0" + (first.getUTCMonth() + 1)
              : first.getUTCMonth() + 1;
          showWarning = true;

          message = (
            <div>
              Ditt tidigast möjliga leveransdatum är {day + "/" + month} på
              grund av{" "}
              {stopProduct.map(
                (x, i) => x + (i + 1 !== stopProduct.length ? ", " : "")
              )}{" "}
              som finns i din varukorg.
            </div>
          );
        }

        let markDisabled = [];
        self.state.deliveryDates.forEach((date) => {
          if (date < earliestDeliveryDate) {
            markDisabled.push(date);
          }
        });

        self.setState({
          earliestDeliveryDate:
            changed && found ? earliestDeliveryDate : replace,
          warningMessage: message,
          showWarning: showWarning,

          disabledDates: markDisabled,
        });
      });
    });
  }
}

export default Cart;
